html {
  --total-scroll-width-px: 0px;
  /* the true size of our background image */
  --total-width-px: 17500px;
  --total-height-px: 1502px;
  --width-px: calc(var(--total-width-px) / 2);
  --height-px: calc(var(--total-height-px) / 2);
  --waterfall-position: 430px;
  --fire-position: 1650px;
  --whare-waka-position: 2543px;
  --whare-waka-door-position: calc(var(--whare-waka-position) + 135px);
  --bark-chips-1-position: calc(var(--whare-waka-position) - 100px);
  --bark-chips-2-position: calc(var(--whare-waka-position) + 300px);
  --waka-making-position: 2820px;
  --beached-waka-position: 4328px;
  --putatara-position: calc(var(--beached-waka-position) - 928px);

  overflow-y: hidden;
  overflow-x: hidden; /*set this to scroll via javaScript when user starts */
  font-family: sriracha;
  box-sizing: padding-box;

  --sky-day-color: #60bde8;
}

body {
  /* min-width: 100%; */
  max-width: 100%;
  position: relative;
  /* background: rgb(37, 134, 172); */
  background-color: rgba(0, 0, 0, 0.9);
  margin: 0;
  text-align: middle;
}

.hidden {
  display: none !important;
}

.gs-dev-tools {
  z-index: 2000; /* force gs-dev tools always on top */
}

#loading-screen {
  /* katie removed */
  /* padding-top: 20%; */
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  color: bisque;
  font-size: xx-large;
  text-align: center;
  z-index: 2000;
}

#end-credits {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 725px;
  display: none;
  overflow: hidden;
  z-index: 1000;
}
#end-credits-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 750px;
}
#end-credits h1 {
  font-size: 4rem;
  letter-spacing: -0.02em;
  line-height: 105%;
  text-transform: uppercase;
  color: #fff0c4;
}
#end-credits h2 {
  color: #fff0c4;
  margin-bottom: -0.2em;
  margin-top: 2em;
}

#end-credits p {
  color: #fff0c4;
  margin-bottom: 0em;
  margin-top: 0em;
  text-align: center;
  width: 80vw;
}


body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  /* background: rgb(48, 35, 10);       */
  background: rgb(104, 59, 0); 
}
body::-webkit-scrollbar-thumb {
  border-radius: 8px;      
  border: 2px solid rgb(255, 255, 255);  
  background: rgb(160, 110, 51); ; 
}

#scroller {
  position: relative;
  height: 1px;
  width: var(--total-scroll-width-px);
}

.background {
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0px;
  height: 100%;
  margin: 0;
  width: 100%;
}

.background-prop {
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
}

#border-container {
  display: inline-block;
  z-index: 999;
  border: 8px solid black;
  top: 0;
  padding: 0px;
  max-height: 750px;
  /* min-width: var(--width-px); */
  position: fixed;
  overflow: hidden;
}

#container {
  /* considered an ambulance at the botttom of cliff.  */
  display: inline-block;
  position: relative;
  left: 0;
  padding: 0px;
  margin: 0;
  /* width: var(--width-px);
  height: var(--height-px); */
  height: 100%;
  /* border: dashed 8px black; */
  /* Katie - changing overflow back to hidden again incase this fixes weird 'end of timeline' issue */
  overflow: hidden;
}

.positioned {
  position: relative;
}

.scene {
  position: relative;
  z-index: -1; /* This enabled the subtitle divs to be grouped inside "subtitle-slots" parent div */
}

#scene3 {
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  transform-origin: 35% 91.5%; /* Zoom in/out from LHS of scene, oddly enough */    
  z-index: -1; /* This enabled the subtitle divs to be grouped inside "subtitle-slots" parent div */
}

#scene3b {
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  /* transform-origin: 75% 50%; */
  z-index: -1; /* This enabled the subtitle divs to be grouped inside "subtitle-slots" parent div */
}

/* Scene 1 */

#sky {
  z-index: 5;
  background-color: var(--sky-day-color);
  height: 75%;
  top: 0;
  width: 120%;
}

#clouds {
  z-index: 10;
  width: unset;
}

#s1-background {
  width: unset;
  z-index: 20;
}

#s3a-sky-background {
  width: unset;
  z-index: 19;
}

#s3a-dunes {
  left: 4122px;
  top: 10px;
  transform: scale(0.8);
  z-index: 21;
}

#s3a-sun-eclipse-1 {
  mix-blend-mode: lighten;
  z-index: 60;
  top: -925px;
  left: 3553px;
  height: unset;
  width: 45%;
}

#s3a-sun-eclipse-2 {
  mix-blend-mode: screen;
  z-index: 21;
  top: -108px;
  left: 3960px;
}

#s3a-clouds1 {
  z-index: 28;
}

#s3a-clouds2 {
  mix-blend-mode: screen;
  z-index: 21;
}

#s3a-clouds3 {
  z-index: 20;
  mix-blend-mode: multiply;
}

#s3a-dark-clouds1 {
  z-index: 21;
}

#s3a-dark-clouds2 {
  z-index: 21;
}

#s3a-dark-clouds3 {
  z-index: 20;
}

#s3b-background {
  background-image: url(../assets/scene3b/Background.png);
  z-index: 20;
}
#s3b-rimu {
  background-image: url(../assets/scene3b/Rimu-Layer-no-river.png);
  /* background-image: url(../assets/scene3b/Rimu-Layer.png); */
  z-index: 25;
}
/* #s3b-rimu-river {
  background-image: url(../assets/scene3b/Rimu-Layer.png);
  z-index: 30;
} */
#s3b-plants-foreground {
  background-image: url(../assets/scene3b/Foreground.png);
  z-index: 45;
  left: 970px;
  transform: scale(0.55);
  top: 130px;
}

#waterfall {
  width: 142px;
  height: 183px;
  top: 34%;
  left: var(--waterfall-position);
  background-image: url(../assets/props/waterfall-small_png24.png);
  z-index: 21;
  background-size: cover;
}


.spacer {
  /* this forces container to be the actual size of images.  background-size then 100% within this. */
  position: relative;
  width: var(--width-px);
  height: var(--height-px);
  max-height: 800px;
}

#ground {
  z-index: 25;
}

.scene1-ground-bg {
  width: unset;
}

/* 
#ground2 {
  z-index: 26;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */

/* #people-placeholder {
  background-image: url(../assets/landscape/landscape-images-750h/merged-people_png8_100.png);
  z-index: 26;
} */

/* temporarily hide this layer incase we want to bring them back  */
/* #trees {
  background-image: url(../assets/landscape/correct/PALM-TREES_png8_100.png);
  z-index: 30;
  bottom: 0;
} */

#plants {
  background-image: url(../assets/landscape/landscape-images-750h/merged-foreground-layers_png8_750h.png);
  z-index: 50;
}

#s3b-hero {
  z-index: 40;
  position: absolute; 
  background-size: cover;
  bottom: 0;
  transform-origin: bottom left;
  overflow-x:visible;
}


#hero {
  left: -300px;
  height: 1054px;
  width: 750px;
  transform: scale(0.55);
  z-index: 40;
  position: absolute;
 
  background-size: cover;
  bottom: 0;
  transform-origin: bottom left;
  overflow-x:visible;
}
#nuku-walking,
#s3-nuku-walking,
#s3b-nuku-walking {
  background: url(../assets/Nukutawhiti/nukutawhi_walkcycle_png8_1054h.png) no-repeat 0 0%;
  height: 100%;
}

#s3b-nuku-walking-shoot {
  background: url(../assets/Nukutawhiti/Nukutawhiti_Shoot_391x578.png) no-repeat 0 0%;
  height: 578px;
  width: 391px;
  background-size: cover;
}

#nuku-profile,
#s3-nuku-profile,
#s3b-nuku-profile{
  background: url(../assets/Nukutawhiti/nuku-profile1_png8_1054h.png) no-repeat 0 0%;
  height: 1054px;
  width: 750px;
  bottom:0;
  position: absolute;
  background-size: cover;
}

#nuku-turn-cycle,
#s3-nuku-turn-cycle,
#s3b-nuku-turn-cycle{
  height: 1054px;
  width: 750px;
  position: absolute;  
  background: url(../assets/Nukutawhiti/nukutawhi_turn_png8_1050h_eyes.png) no-repeat 0 0%;
  background-size: cover;
  opacity: 0;
  visibility: visible;
  bottom: 0;
}
#nuku-turned,
#s3-nuku-turned,
#s3b-nuku-turned{
  height: 1054px;
  width: 900px;
  margin-left:-75px;
  position: absolute;  
  background: url(../assets/Nukutawhiti/nuku-turned_png8_1054h.png) no-repeat 0 0%;
  background-size: cover;
  opacity: 1 !important;
  visibility: visible;
  display: none;
  bottom: 0;
}


/* #hero {
  transform: translate3d(-2252.23px, 1400px, 0px) rotate(-6.9666deg) scale(1.7);
} */

#boat-nuku-turn-cycle{
  position: relative;  
  background: url(../assets/Nukutawhiti/nukutawhi_turn_png8_1050h_eyes.png) no-repeat 0 0%;
  height: 1054px;
  width: 750px;
  top: -1378px;
  left: -186px;
  background-size: cover;
}


#boat-nuku-turned{
  position: relative;  
  background: url(../assets/Nukutawhiti/nuku-turned_png8_1054h.png) no-repeat 0 0%;
  height: 1054px;
  width: 900px;
  top: -1378px;
  left: -186px;
  background-size: cover;
}


#boat-nuku-bend-cycle,
#s3-boat-nuku-bend-cycle{
  position: fixed;
  background: url(../assets/Nukutawhiti/Nukutawhiti-45-Bend-transparent_780w_1054h.png) no-repeat 0 0%;
  filter: brightness(1.26);
  width: 750px;
  height: 1054px;
 
  top: -333px;
  left: -176px;

  background-size: cover;
}

.scene2-moana {
  z-index: 15;
}

#nuku-side-windy-hair,
#s3-nuku-side-windy-hair{
  background: url(../assets/Nukutawhiti/hair-and-body/nuku-profile1_png24_NOHAIR@1054h-8.png) no-repeat 0 0%;
  height: 1054px;
  width: 750px;
  top: -325px;
  left: -186px;
  position: relative;
  background-size: cover;
}

#nuku-windy-hair,
#s3-nuku-windy-hair {
  position: relative;
  width: 203px;
  height: 150px;
  left: 261px;
  top: 49px;
  background-image: url(../assets/Nukutawhiti/hair-and-body/nuku-hair-blowing@150-png8.png);
  background-size: cover;
  transform-origin: 50% 50%;
}


#s3-falling-feather {
  position: absolute;
  width: 64px;
  height: 90px;
  left: 7365px;
  /* left: 5980px; */
  top: 440px;
  background-image: url(../assets/scene3a/Animated/Feathers-Falling-321x450.png);
  background-size: cover;
  transform-origin: 50% 50%;
  opacity: 0;
  z-index: 40;
}

.manu {
  position: absolute;
  width: 207px;
  height: 124px;
  background-image: url(../assets/scene3a/Animated/Manu-207x124.png);
  background-size: cover;
  transform-origin: 50% 50%;
  opacity: 0;
  z-index: 27;
}

#s2-manu1, #s2-manu2, #s2-manu3, #s3-manu3, #s3-manu4, #s3-manu5 {
  z-index: 40;
}

#fire {
  position: absolute;
  height: 225px;
  top: 20px;
  left: -120px;
  /* z-index: 39; */
}

#fire-characters{
  left: calc(var(--fire-position) + 120px);
  top: 46%;
  width:100px;
  height:auto;  
  z-index: 39;
}

#tokaakuaku {
  width: 100px;
    height: auto;
    position: absolute;
    top: 10px;
    left:43px;
}
#kupe {
  width: 230px;
    height: auto;
    top: -22px;
    left: 74px;
    position: absolute;
    transform: scaleX(-1);
}
/* Rock in-front of Tokaakuaku */
#fire-rock-1 {
  position: absolute;
  top: 100px;
  left: 43px;
  width: 69px;
}
#fire-bush-sml {
  position: absolute;
  top: 100px;
  left: -180px;
  width: 50px;
}
#fire-bush-lrg {
  position: absolute;
  top: 192px;
  left: 49px;
  width: 336px;
}
#fire-drift-wood-1 {
  position: absolute;
  top: 46px;
  left: 105px;
  width: 338px;
  transform: scaleX(-1) rotate(-44deg);
}
#fire-drift-wood-2 {
  position: absolute;
  top: 32px;
  left: 172px;
  width: 270px;
  transform: rotate(-40deg);
  /* top: 111px;
  left: -383px;
  width: 214px;
  transform: scaleX(-1) rotate(-2deg); */
}
#fire-drift-wood-3 {
  position: absolute;
  top: 40px;
  left: -241px;
  width: 237px;
  transform: rotate(-13deg);
}

.prop {
  position: fixed;
}

#whare-waka {
  position: absolute;
  width: 506px;
  height: auto;
  bottom: 38%;
  left: var(--whare-waka-position);
  z-index: 39;
}

#whare-waka-door{
  position: absolute;
  width: 240px;
  height: auto;
  bottom: 37%;
  left: var(--whare-waka-door-position);
  z-index: 41;
}

#whare-waka-table-toki {
  position: absolute;
  width: 139px;
  height: auto;
  bottom: 35%;
  left: 2724px;
  z-index: 41;
}

#whare-waka-sail {
  position: absolute;
  width: 616px;
  height: auto;
  bottom: 26%;
  left: 2298px;
  z-index: 38;
  transform: rotate(-7deg);
}

.bark-chips{
  width: 235px;
  height: 242px;
  /* position: absolute; */
  background-size: cover;
  bottom:38%;
  background-image: url(../assets/props/bark-chips_7s_6fps_175h.png);   
  z-index: 42;
}

#bark-chips-lhs{  
  left: var(--bark-chips-1-position);  
}

#bark-chips-rhs{
  left: var(--bark-chips-2-position);
  transform: scaleX(-1);  
}


#beached-waka,
#s3b-beached-waka {
  position: absolute;

  width: 350px;
  height: 260px;
  transform: scale(3.2);
  bottom: 58%;
  left: calc(var(--beached-waka-position) + 172px);
  z-index: 39;
  background-image: url(../assets/props/beached-waka.png);
  background-size: cover;
}
#s3b-beached-waka {
  left: 2290px;
  transform: scale(5) rotate(-5deg);
  top: -47px;
}

#waka,
#s3-waka {
  position: absolute;
  transform: scale(1.7);
  width: 350px;
  height: 260px;
  z-index: 27;
  bottom: 35%;
  left: var(--beached-waka-position);
}

#waka-sail,
#s3-waka-sail {
  position: relative;
  width: 350px;
  height: 260px;
  margin-bottom: -260px;
  z-index: 38;
  background-image: url(../assets/props/waka-sail.png);
  background-size: cover;
}

#waka-body,
#s3-waka-body {
  position: relative;
  z-index: 39;
  width: 350px;
  height: 260px;
  margin-bottom: -260px;
  background-image: url(../assets/props/waka-body.png); /* TODO: replace with <img/> tag for performance */
  background-size: cover;
}

#waka-hero,
#s3-waka-hero {
  z-index: 40;
  height: 1054px;
  width: 750px;
  margin-left: -32px;
  margin-top: -33px;
  transform-origin: 31% 22%;
}

#waka-net {
  z-index: 40;
  transform: scale(0.6) rotate(-9deg);
  left: 5510px;
  top: 515px;
  transform-origin: 180px -11px;
}
#waka-net-left {
  background-image: url(../assets/props/net/net-left.png);
  background-size: cover;
  width: 153px;
  height: 177px;
}
#waka-net-middle {
  background-image: url(../assets/props/net/net-middle.png);
  background-size: cover;
  width: 235px;
  height: 138px;
  left: 81px;
  top: 2px;
}
#waka-net-right {
  background-image: url(../assets/props/net/net-right.png);
  background-size: cover;
  width: 286px;
  height: 142px;
  top: 43px;
  left: 61px;
}

#rock-with-waves {
  background: url(../assets/props/rock-with-waves.svg) no-repeat;
  width: 400px;
  height: 155px;
  z-index: 28;
}

#dancing-children-shadows,
#s3-dancing-children-shadows {
  background: url(../assets/props/dancing-children@365x500-8.png);
  background-size: auto;
  background-size: cover;
  transform-origin: 50% 50%;
  position: relative;
  width: 365px;
  height: 500px;
  left: -12px;
  top: -98px;
  transform: scale(0.14);
  opacity: 0.6;
}

#taniwha_brown {
  background: url(../assets/props/taniwha/brown-taniwha.svg) no-repeat;
  width: 100px;
  height: 120px;
  z-index: 27;
}
#taniwha_green {
  background: url(../assets/props/taniwha/green-taniwha.svg) no-repeat;
  width: 100px;
  height: 120px;
  z-index: 31;
}
#taniwha_purple {
  background: url(../assets/props/taniwha/purple-taniwha.svg) no-repeat;
  width: 100px;
  height: 120px;
  z-index: 21;
}
#taniwha_orange {
  width: 70px;
  height: 140px;
  z-index: 28;
  opacity: 0;
}
#taniwha_orange_body {
  transform-origin: 59% 35%;
  width: 100%;
  height: 100%;
}
#taniwha_orange_head {
  background: url(../assets/props/taniwha/taniwha-head.svg) no-repeat;
  top: 8%;
  left: 20%;
  width: 100%;
  height: 42%;
  transform-origin: 50% 50%;
}
#taniwha_orange_torso
{
  background: url(../assets/props/taniwha/taniwha-body.svg) no-repeat;
  top: 33%;
  left: 0;
  width: 100%;
  height: 75%;
}
#taniwha_orange_left_arm {
  background: url(../assets/props/taniwha/taniwha-arm-left.svg) no-repeat;
  top: 40%;
  left: -2%;
  width: 60%;
  height: 36%;
  transform-origin: 85% 18%;
}
#taniwha_orange_right_arm {
  background: url(../assets/props/taniwha/taniwha-arm-right.svg) no-repeat;
  top: 40%;
  left: 60%;
  width: 60%;
  height: 36%;
  transform-origin: 9% 9%;
}


#waka-kuri,
#s3-waka-kuri {
  width: 133px;
  height: 64px;
  bottom: 30px;
  right: 80px;
  position: absolute;
  transform: scale(0.3);
}
#waka-kuri-flapping-ears,
#s3-waka-kuri-flapping-ears {
  background: url(../assets/props/kuri-sprite_w88_h100.png) no-repeat 0 0%;
  width: 88px;
  height: 100px;
  top: 240px;
  left: 176px;
  position: relative;
  background-size: cover;
  transform: scale(0.25);
  margin-top: -100px;
  z-index: 40;
  filter: brightness(140%);
}
.beach-folk {
  width: 1050px;
  height: 500px;
  position: absolute;
  bottom: 6%;
  left: 3100px;
}
#beach-folk-fg{
  z-index: 41;
}
#marae-games {
  width: 600px;
  height: auto;
  position: absolute;
  top: -60px;
  left: -60px;
  transform: scale(0.3);
}
#marae-girl {
  width: 200px;
  height: auto;
  position: absolute;
  bottom: -32px;
  right: 183px;
  transform: scale(.7);
}
#marae-kids {
  width: 270px;
  height: auto;
  position: absolute;
  bottom: -24px;
  right: 61px;
  transform: scale(.55);
}
#marae-mum {
  width: 300px;
  height: auto;
  position: absolute;
  bottom: -60px;
  right: 260px;
  transform: scale(.75);
}
#beach-folk-drift-wood {
  width: 300px;
  height: auto;
  position: absolute;
  bottom: 80px;
  right: 390px;
}

#beach-characters {
  width:200px;
  height:200px;
  top:41%;
  left:3858px;
}
#beach-kupe {
  width: 130px;
  height: auto;
  transform: scaleX(-1);
  top: 20px;
  position: absolute;
}

#beach-tokaakuaku {
  width: 100px;
  height: auto;
  position: absolute;
  top: 25px;
  left: 6px;
  transform: scaleX(-1);
}

#putatara {
  width: 257px;
  height: 425px;
  bottom: 37%;
  left: var(--putatara-position);
  background-image: url(../assets/props/putatara-sprite_png8_425h.png);
  z-index: 39;
  background-size: cover;
  /* transform: scaleX(0.65) scaleY(0.65); */
  transform-origin: right bottom;
}

#putatara-rock {
  left: calc(var(--putatara-position) + 99px);
  bottom: 35%;
  width: 100px;
}




@font-face {
  font-family: sriracha;
  src: url(../assets/fonts/Sriracha-Regular.ttf);
}

#progress {
  margin: 2rem;
  font-family: sriracha;
}

.panel {
  width: 0px;
  height: 100%;
  z-index: 999;
}

/* #panel1 {
  left: 14%;
}

#panel2 {
  left: 45%;
} */


.scroll-prompt-container {
  width: 100%;
  height: 758px;
  position: fixed;  
  z-index: 999;
  display: block;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  pointer-events: none;
}
img.scroll-arrow {
  display: block;
  width: 48px;
  position: relative;
  margin: 0 auto;
}

.scroll-prompt {
  margin-bottom: 40px;
  background-image: url(../icons/scroll.svg);
  padding: 20px 20px 20px 20px;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 123px;
}

.scroll-text {  
  font-size: 2.25rem;
  text-transform: uppercase;
  line-height: 100%;
}


/* Scene 2 waves */

.bg-wave {
  background: url(../assets/landscape/wave-1.png) repeat-x;
  position: absolute;
  height: 600px;
  width: 1600%;
}

#bg-wave-1 {
  z-index: 20;
  animation: wave1 400s linear infinite;
  bottom: 290px;

  height: 40px;
  width: 800%;
  background-size: 1% 40px;
  image-rendering: pixelated;
}

#bg-wave-2 {
  z-index: 22;
  animation: wave2 150s linear infinite;
  left: -50vw;

  height: 75px;
  width: 1000%;
  bottom: 242px;

  background-size: 1.5% 80px;
  image-rendering: pixelated;
}

#bg-wave-3 {
  z-index: 24;
  animation: wave3 70s linear infinite;
  left: -100vw;
  width: 1200%;

  bottom: -305px;
  background-size: 2% 140px;
  image-rendering: pixelated;
}

#bg-wave-4 {
  z-index: 26;
  animation: wave4 40s linear infinite;
  left: -150vw;

  /* top: -30px; */
  bottom: -345px;
  background-size: 3% 210px;
  width: 1500%;
}

#bg-wave-5 {
  z-index: 28;
  animation: wave5 30s linear infinite;
  left: -150vw;

  bottom: -383px;
  background-size: 4% 280px;
  width: 2000%;
}

#bg-wave-6 {
  z-index: 30;
  animation: wave6 22s linear infinite;
  left: -150vw;

  bottom: -445px;
  background-size: 5% 350px;
  width: 2500%;
}

#bg-wave-7 {
  z-index: 32;
  animation: wave7 20s linear infinite;
  left: -150vw;

  /* top: 135px; */
  bottom: -505px;
  background-size: 6% 455px;
  width: 3250%;
}

/* Scene 3a waves */

.s3a-bg-wave {
  /* background: url(../assets/landscape/wave-1.png) repeat-x; */
  background: url(../assets/scene3a/Ocean-Layers/Scene-3.a-Light-blue-waves_big.png) repeat-x;
  /* background: url(../assets/scene3a/Ocean-Layers/Wave-3.png) repeat-x; */
  position: absolute;
  height: 600px;
  width: 1600%;
}

#s3a-bg-wave-1 {
  /* background: url(../assets/scene3a/Ocean-Layers/Wave-1.png) repeat-x; */
  z-index: 20;
  animation: wave1 400s linear infinite;
  animation-delay: -5s;
  bottom: 290px;

  height: 40px;
  width: 800%;
  background-size: 1% 40px;
  image-rendering: pixelated;
}

#s3a-bg-wave-2 {
  /* background: url(../assets/scene3a/Ocean-Layers/Wave-2.png) repeat-x; */
  z-index: 22;
  animation: wave2 150s linear infinite;
  left: -50vw;

  height: 75px;
  width: 1000%;
  bottom: 242px;

  background-size: 1.5% 80px;
  image-rendering: pixelated;
}

#s3a-bg-wave-3 {
  /* background: url(../assets/scene3a/Ocean-Layers/Wave-3.png) repeat-x; */
  z-index: 24;
  animation: wave3 70s linear infinite;
  left: -100vw;
  width: 1200%;

  bottom: -305px;
  background-size: 2% 140px;
  image-rendering: pixelated;
}

#s3a-bg-wave-4 {
  /* background: url(../assets/scene3a/Ocean-Layers/Wave-4.png) repeat-x; */
  z-index: 26;
  animation: wave4 40s linear infinite;
  left: -150vw;

  /* top: -30px; */
  bottom: -345px;
  background-size: 3% 210px;
  width: 1500%;
}

#s3a-bg-wave-5 {
  /* background: url(../assets/scene3a/Ocean-Layers/Wave-5.png) repeat-x; */
  z-index: 28;
  animation: wave5 30s linear infinite;
  left: -150vw;

  bottom: -383px;
  background-size: 4% 280px;
  width: 2000%;
}

#s3a-bg-wave-6 {
  /* background: url(../assets/scene3a/Ocean-Layers/Wave-6.png) repeat-x; */
  z-index: 30;
  animation: wave6 22s linear infinite;
  left: -150vw;

  bottom: -445px;
  background-size: 5% 350px;
  width: 2500%;
}


/* Animation keyframes for waves */

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -100%;
  }
}

@keyframes wave1 {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -200%;
  }
}

@keyframes wave2 {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: calc(-100% * 1600/1000);
  }
}

@keyframes wave3 {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: calc(-100% * 1.2);
  }
}

@keyframes wave4 {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: calc(-100% * 1.8);
  }
}

@keyframes wave5 {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: calc(-100% * 2.4);
  }
}

@keyframes wave6 {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: calc(-100% * 1.25);
  }
}

@keyframes wave7 {
  0% {
    margin-left: 0;
  }
  100% {
    /* margin-left: calc(-100% * 15.60); */
    margin-left: calc(-100% * 3.9);
  }
}

@keyframes scale1_2 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
}

/* Scene2 props */

.sml-mountain {
  width: 140px;
  height: 51px;
  position: fixed;
  background-repeat: no-repeat;
  z-index: 19;
}

#mountains-1 {
  background-image: url(../assets/props/mountains-1.svg);
}

#mountains-2 {
  background-image: url(../assets/props/mountains-2.svg);
}
