html {
  --total-scroll-width-px: 0px;
  --total-width-px: 17500px;
  --total-height-px: 1502px;
  --width-px: calc(var(--total-width-px) / 2);
  --height-px: calc(var(--total-height-px) / 2);
  --waterfall-position: 430px;
  --fire-position: 1650px;
  --whare-waka-position: 2543px;
  --whare-waka-door-position: calc(var(--whare-waka-position)  + 135px);
  --bark-chips-1-position: calc(var(--whare-waka-position)  - 100px);
  --bark-chips-2-position: calc(var(--whare-waka-position)  + 300px);
  --waka-making-position: 2820px;
  --beached-waka-position: 4328px;
  --putatara-position: calc(var(--beached-waka-position)  - 928px);
  box-sizing: padding-box;
  --sky-day-color: #60bde8;
  font-family: sriracha;
  overflow: hidden;
}

body {
  max-width: 100%;
  text-align: middle;
  background-color: #000000e6;
  margin: 0;
  position: relative;
}

.hidden {
  display: none !important;
}

.gs-dev-tools {
  z-index: 2000;
}

#loading-screen {
  height: 100%;
  width: 100%;
  color: bisque;
  text-align: center;
  z-index: 2000;
  background-color: #000;
  font-size: xx-large;
  display: block;
  position: fixed;
  top: 0;
}

#end-credits {
  width: 100vw;
  height: 725px;
  z-index: 1000;
  display: none;
  position: fixed;
  left: 0;
  overflow: hidden;
}

#end-credits-container {
  width: 100vw;
  height: 750px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#end-credits h1 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff0c4;
  font-size: 4rem;
  line-height: 105%;
}

#end-credits h2 {
  color: #fff0c4;
  margin-top: 2em;
  margin-bottom: -.2em;
}

#end-credits p {
  color: #fff0c4;
  text-align: center;
  width: 80vw;
  margin-top: 0;
  margin-bottom: 0;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #683b00;
}

body::-webkit-scrollbar-thumb {
  background: #a06e33;
  border: 2px solid #fff;
  border-radius: 8px;
}

#scroller {
  height: 1px;
  width: var(--total-scroll-width-px);
  position: relative;
}

.background {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: border-box;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.background-prop {
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: border-box;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

#border-container {
  z-index: 999;
  max-height: 750px;
  border: 8px solid #000;
  padding: 0;
  display: inline-block;
  position: fixed;
  top: 0;
  overflow: hidden;
}

#container {
  height: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  left: 0;
  overflow: hidden;
}

.positioned {
  position: relative;
}

.scene {
  z-index: -1;
  position: relative;
}

#scene3 {
  opacity: 0;
  transform-origin: 35% 91.5%;
  z-index: -1;
  display: none;
  position: absolute;
  top: 0;
}

#scene3b {
  opacity: 0;
  z-index: -1;
  display: none;
  position: absolute;
  top: 0;
}

#sky {
  z-index: 5;
  background-color: var(--sky-day-color);
  height: 75%;
  width: 120%;
  top: 0;
}

#clouds {
  z-index: 10;
  width: unset;
}

#s1-background {
  width: unset;
  z-index: 20;
}

#s3a-sky-background {
  width: unset;
  z-index: 19;
}

#s3a-dunes {
  z-index: 21;
  top: 10px;
  left: 4122px;
  transform: scale(.8);
}

#s3a-sun-eclipse-1 {
  mix-blend-mode: lighten;
  z-index: 60;
  height: unset;
  width: 45%;
  top: -925px;
  left: 3553px;
}

#s3a-sun-eclipse-2 {
  mix-blend-mode: screen;
  z-index: 21;
  top: -108px;
  left: 3960px;
}

#s3a-clouds1 {
  z-index: 28;
}

#s3a-clouds2 {
  mix-blend-mode: screen;
  z-index: 21;
}

#s3a-clouds3 {
  z-index: 20;
  mix-blend-mode: multiply;
}

#s3a-dark-clouds1, #s3a-dark-clouds2 {
  z-index: 21;
}

#s3a-dark-clouds3 {
  z-index: 20;
}

#s3b-background {
  z-index: 20;
  background-image: url("Background.b0378960.png");
}

#s3b-rimu {
  z-index: 25;
  background-image: url("Rimu-Layer-no-river.cea38f07.png");
}

#s3b-plants-foreground {
  z-index: 45;
  background-image: url("Foreground.2f42c1ec.png");
  top: 130px;
  left: 970px;
  transform: scale(.55);
}

#waterfall {
  width: 142px;
  height: 183px;
  top: 34%;
  left: var(--waterfall-position);
  z-index: 21;
  background-image: url("waterfall-small_png24.54dd63c8.png");
  background-size: cover;
}

.spacer {
  width: var(--width-px);
  height: var(--height-px);
  max-height: 800px;
  position: relative;
}

#ground {
  z-index: 25;
}

.scene1-ground-bg {
  width: unset;
}

#plants {
  z-index: 50;
  background-image: url("merged-foreground-layers_png8_750h.8d7115be.png");
}

#s3b-hero {
  z-index: 40;
  transform-origin: 0 100%;
  background-size: cover;
  position: absolute;
  bottom: 0;
  overflow-x: visible;
}

#hero {
  height: 1054px;
  width: 750px;
  z-index: 40;
  transform-origin: 0 100%;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: -300px;
  overflow-x: visible;
  transform: scale(.55);
}

#nuku-walking, #s3-nuku-walking, #s3b-nuku-walking {
  height: 100%;
  background: url("nukutawhi_walkcycle_png8_1054h.c9d1ae87.png") no-repeat;
}

#s3b-nuku-walking-shoot {
  height: 578px;
  width: 391px;
  background: url("Nukutawhiti_Shoot_391x578.5697e6ae.png") 0 0 / cover no-repeat;
}

#nuku-profile, #s3-nuku-profile, #s3b-nuku-profile {
  height: 1054px;
  width: 750px;
  background: url("nuku-profile1_png8_1054h.c42a9915.png") 0 0 / cover no-repeat;
  position: absolute;
  bottom: 0;
}

#nuku-turn-cycle, #s3-nuku-turn-cycle, #s3b-nuku-turn-cycle {
  height: 1054px;
  width: 750px;
  opacity: 0;
  visibility: visible;
  background: url("nukutawhi_turn_png8_1050h_eyes.094d149b.png") 0 0 / cover no-repeat;
  position: absolute;
  bottom: 0;
}

#nuku-turned, #s3-nuku-turned, #s3b-nuku-turned {
  height: 1054px;
  width: 900px;
  visibility: visible;
  background: url("nuku-turned_png8_1054h.3c568c49.png") 0 0 / cover no-repeat;
  margin-left: -75px;
  display: none;
  position: absolute;
  bottom: 0;
  opacity: 1 !important;
}

#boat-nuku-turn-cycle {
  height: 1054px;
  width: 750px;
  background: url("nukutawhi_turn_png8_1050h_eyes.094d149b.png") 0 0 / cover no-repeat;
  position: relative;
  top: -1378px;
  left: -186px;
}

#boat-nuku-turned {
  height: 1054px;
  width: 900px;
  background: url("nuku-turned_png8_1054h.3c568c49.png") 0 0 / cover no-repeat;
  position: relative;
  top: -1378px;
  left: -186px;
}

#boat-nuku-bend-cycle, #s3-boat-nuku-bend-cycle {
  filter: brightness(1.26);
  width: 750px;
  height: 1054px;
  background: url("Nukutawhiti-45-Bend-transparent_780w_1054h.e80ada48.png") 0 0 / cover no-repeat;
  position: fixed;
  top: -333px;
  left: -176px;
}

.scene2-moana {
  z-index: 15;
}

#nuku-side-windy-hair, #s3-nuku-side-windy-hair {
  height: 1054px;
  width: 750px;
  background: url("nuku-profile1_png24_NOHAIR@1054h-8.357f59fa.png") 0 0 / cover no-repeat;
  position: relative;
  top: -325px;
  left: -186px;
}

#nuku-windy-hair, #s3-nuku-windy-hair {
  width: 203px;
  height: 150px;
  transform-origin: 50%;
  background-image: url("nuku-hair-blowing@150-png8.6bbd1714.png");
  background-size: cover;
  position: relative;
  top: 49px;
  left: 261px;
}

#s3-falling-feather {
  width: 64px;
  height: 90px;
  transform-origin: 50%;
  opacity: 0;
  z-index: 40;
  background-image: url("Feathers-Falling-321x450.fef9b12d.png");
  background-size: cover;
  position: absolute;
  top: 440px;
  left: 7365px;
}

.manu {
  width: 207px;
  height: 124px;
  transform-origin: 50%;
  opacity: 0;
  z-index: 27;
  background-image: url("Manu-207x124.556ac213.png");
  background-size: cover;
  position: absolute;
}

#s2-manu1, #s2-manu2, #s2-manu3, #s3-manu3, #s3-manu4, #s3-manu5 {
  z-index: 40;
}

#fire {
  height: 225px;
  position: absolute;
  top: 20px;
  left: -120px;
}

#fire-characters {
  left: calc(var(--fire-position)  + 120px);
  width: 100px;
  height: auto;
  z-index: 39;
  top: 46%;
}

#tokaakuaku {
  width: 100px;
  height: auto;
  position: absolute;
  top: 10px;
  left: 43px;
}

#kupe {
  width: 230px;
  height: auto;
  position: absolute;
  top: -22px;
  left: 74px;
  transform: scaleX(-1);
}

#fire-rock-1 {
  width: 69px;
  position: absolute;
  top: 100px;
  left: 43px;
}

#fire-bush-sml {
  width: 50px;
  position: absolute;
  top: 100px;
  left: -180px;
}

#fire-bush-lrg {
  width: 336px;
  position: absolute;
  top: 192px;
  left: 49px;
}

#fire-drift-wood-1 {
  width: 338px;
  position: absolute;
  top: 46px;
  left: 105px;
  transform: scaleX(-1)rotate(-44deg);
}

#fire-drift-wood-2 {
  width: 270px;
  position: absolute;
  top: 32px;
  left: 172px;
  transform: rotate(-40deg);
}

#fire-drift-wood-3 {
  width: 237px;
  position: absolute;
  top: 40px;
  left: -241px;
  transform: rotate(-13deg);
}

.prop {
  position: fixed;
}

#whare-waka {
  width: 506px;
  height: auto;
  bottom: 38%;
  left: var(--whare-waka-position);
  z-index: 39;
  position: absolute;
}

#whare-waka-door {
  width: 240px;
  height: auto;
  bottom: 37%;
  left: var(--whare-waka-door-position);
  z-index: 41;
  position: absolute;
}

#whare-waka-table-toki {
  width: 139px;
  height: auto;
  z-index: 41;
  position: absolute;
  bottom: 35%;
  left: 2724px;
}

#whare-waka-sail {
  width: 616px;
  height: auto;
  z-index: 38;
  position: absolute;
  bottom: 26%;
  left: 2298px;
  transform: rotate(-7deg);
}

.bark-chips {
  width: 235px;
  height: 242px;
  z-index: 42;
  background-image: url("bark-chips_7s_6fps_175h.2d5987c7.png");
  background-size: cover;
  bottom: 38%;
}

#bark-chips-lhs {
  left: var(--bark-chips-1-position);
}

#bark-chips-rhs {
  left: var(--bark-chips-2-position);
  transform: scaleX(-1);
}

#beached-waka, #s3b-beached-waka {
  width: 350px;
  height: 260px;
  bottom: 58%;
  left: calc(var(--beached-waka-position)  + 172px);
  z-index: 39;
  background-image: url("beached-waka.68f645a1.png");
  background-size: cover;
  position: absolute;
  transform: scale(3.2);
}

#s3b-beached-waka {
  top: -47px;
  left: 2290px;
  transform: scale(5)rotate(-5deg);
}

#waka, #s3-waka {
  width: 350px;
  height: 260px;
  z-index: 27;
  bottom: 35%;
  left: var(--beached-waka-position);
  position: absolute;
  transform: scale(1.7);
}

#waka-sail, #s3-waka-sail {
  width: 350px;
  height: 260px;
  z-index: 38;
  background-image: url("waka-sail.991faa7f.png");
  background-size: cover;
  margin-bottom: -260px;
  position: relative;
}

#waka-body, #s3-waka-body {
  z-index: 39;
  width: 350px;
  height: 260px;
  background-image: url("waka-body.e52a3ddd.png");
  background-size: cover;
  margin-bottom: -260px;
  position: relative;
}

#waka-hero, #s3-waka-hero {
  z-index: 40;
  height: 1054px;
  width: 750px;
  transform-origin: 31% 22%;
  margin-top: -33px;
  margin-left: -32px;
}

#waka-net {
  z-index: 40;
  transform-origin: 180px -11px;
  top: 515px;
  left: 5510px;
  transform: scale(.6)rotate(-9deg);
}

#waka-net-left {
  width: 153px;
  height: 177px;
  background-image: url("net-left.6102f342.png");
  background-size: cover;
}

#waka-net-middle {
  width: 235px;
  height: 138px;
  background-image: url("net-middle.74c10b34.png");
  background-size: cover;
  top: 2px;
  left: 81px;
}

#waka-net-right {
  width: 286px;
  height: 142px;
  background-image: url("net-right.344f69af.png");
  background-size: cover;
  top: 43px;
  left: 61px;
}

#rock-with-waves {
  width: 400px;
  height: 155px;
  z-index: 28;
  background: url("rock-with-waves.a3aa63ae.svg") no-repeat;
}

#dancing-children-shadows, #s3-dancing-children-shadows {
  transform-origin: 50%;
  width: 365px;
  height: 500px;
  opacity: .6;
  background: url("dancing-children@365x500-8.8bf4dd2e.png") 0 0 / cover;
  position: relative;
  top: -98px;
  left: -12px;
  transform: scale(.14);
}

#taniwha_brown {
  width: 100px;
  height: 120px;
  z-index: 27;
  background: url("brown-taniwha.f5bab8f2.svg") no-repeat;
}

#taniwha_green {
  width: 100px;
  height: 120px;
  z-index: 31;
  background: url("green-taniwha.7db88ed1.svg") no-repeat;
}

#taniwha_purple {
  width: 100px;
  height: 120px;
  z-index: 21;
  background: url("purple-taniwha.3c8d54c7.svg") no-repeat;
}

#taniwha_orange {
  width: 70px;
  height: 140px;
  z-index: 28;
  opacity: 0;
}

#taniwha_orange_body {
  transform-origin: 59% 35%;
  width: 100%;
  height: 100%;
}

#taniwha_orange_head {
  width: 100%;
  height: 42%;
  transform-origin: 50%;
  background: url("taniwha-head.a937a73c.svg") no-repeat;
  top: 8%;
  left: 20%;
}

#taniwha_orange_torso {
  width: 100%;
  height: 75%;
  background: url("taniwha-body.2b589f64.svg") no-repeat;
  top: 33%;
  left: 0;
}

#taniwha_orange_left_arm {
  width: 60%;
  height: 36%;
  transform-origin: 85% 18%;
  background: url("taniwha-arm-left.7a8dcb25.svg") no-repeat;
  top: 40%;
  left: -2%;
}

#taniwha_orange_right_arm {
  width: 60%;
  height: 36%;
  transform-origin: 9% 9%;
  background: url("taniwha-arm-right.6d0dbb18.svg") no-repeat;
  top: 40%;
  left: 60%;
}

#waka-kuri, #s3-waka-kuri {
  width: 133px;
  height: 64px;
  position: absolute;
  bottom: 30px;
  right: 80px;
  transform: scale(.3);
}

#waka-kuri-flapping-ears, #s3-waka-kuri-flapping-ears {
  width: 88px;
  height: 100px;
  z-index: 40;
  filter: brightness(140%);
  background: url("kuri-sprite_w88_h100.9255d786.png") 0 0 / cover no-repeat;
  margin-top: -100px;
  position: relative;
  top: 240px;
  left: 176px;
  transform: scale(.25);
}

.beach-folk {
  width: 1050px;
  height: 500px;
  position: absolute;
  bottom: 6%;
  left: 3100px;
}

#beach-folk-fg {
  z-index: 41;
}

#marae-games {
  width: 600px;
  height: auto;
  position: absolute;
  top: -60px;
  left: -60px;
  transform: scale(.3);
}

#marae-girl {
  width: 200px;
  height: auto;
  position: absolute;
  bottom: -32px;
  right: 183px;
  transform: scale(.7);
}

#marae-kids {
  width: 270px;
  height: auto;
  position: absolute;
  bottom: -24px;
  right: 61px;
  transform: scale(.55);
}

#marae-mum {
  width: 300px;
  height: auto;
  position: absolute;
  bottom: -60px;
  right: 260px;
  transform: scale(.75);
}

#beach-folk-drift-wood {
  width: 300px;
  height: auto;
  position: absolute;
  bottom: 80px;
  right: 390px;
}

#beach-characters {
  width: 200px;
  height: 200px;
  top: 41%;
  left: 3858px;
}

#beach-kupe {
  width: 130px;
  height: auto;
  position: absolute;
  top: 20px;
  transform: scaleX(-1);
}

#beach-tokaakuaku {
  width: 100px;
  height: auto;
  position: absolute;
  top: 25px;
  left: 6px;
  transform: scaleX(-1);
}

#putatara {
  width: 257px;
  height: 425px;
  bottom: 37%;
  left: var(--putatara-position);
  z-index: 39;
  transform-origin: 100% 100%;
  background-image: url("putatara-sprite_png8_425h.80a123fb.png");
  background-size: cover;
}

#putatara-rock {
  left: calc(var(--putatara-position)  + 99px);
  width: 100px;
  bottom: 35%;
}

@font-face {
  font-family: sriracha;
  src: url("Sriracha-Regular.29479745.ttf");
}

#progress {
  margin: 2rem;
  font-family: sriracha;
}

.panel {
  width: 0;
  height: 100%;
  z-index: 999;
}

.scroll-prompt-container {
  width: 100%;
  height: 758px;
  z-index: 999;
  pointer-events: none;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

img.scroll-arrow {
  width: 48px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.scroll-prompt {
  min-width: 123px;
  background-image: url("scroll.ab74d412.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 40px;
  padding: 20px;
}

.scroll-text {
  text-transform: uppercase;
  font-size: 2.25rem;
  line-height: 100%;
}

.bg-wave {
  height: 600px;
  width: 1600%;
  background: url("wave-1.89f90ab6.png") repeat-x;
  position: absolute;
}

#bg-wave-1 {
  z-index: 20;
  height: 40px;
  width: 800%;
  image-rendering: pixelated;
  background-size: 1% 40px;
  animation: wave1 400s linear infinite;
  bottom: 290px;
}

#bg-wave-2 {
  z-index: 22;
  height: 75px;
  width: 1000%;
  image-rendering: pixelated;
  background-size: 1.5% 80px;
  animation: wave2 150s linear infinite;
  bottom: 242px;
  left: -50vw;
}

#bg-wave-3 {
  z-index: 24;
  width: 1200%;
  image-rendering: pixelated;
  background-size: 2% 140px;
  animation: wave3 70s linear infinite;
  bottom: -305px;
  left: -100vw;
}

#bg-wave-4 {
  z-index: 26;
  width: 1500%;
  background-size: 3% 210px;
  animation: wave4 40s linear infinite;
  bottom: -345px;
  left: -150vw;
}

#bg-wave-5 {
  z-index: 28;
  width: 2000%;
  background-size: 4% 280px;
  animation: wave5 30s linear infinite;
  bottom: -383px;
  left: -150vw;
}

#bg-wave-6 {
  z-index: 30;
  width: 2500%;
  background-size: 5% 350px;
  animation: wave6 22s linear infinite;
  bottom: -445px;
  left: -150vw;
}

#bg-wave-7 {
  z-index: 32;
  width: 3250%;
  background-size: 6% 455px;
  animation: wave7 20s linear infinite;
  bottom: -505px;
  left: -150vw;
}

.s3a-bg-wave {
  height: 600px;
  width: 1600%;
  background: url("Scene-3.a-Light-blue-waves_big.dcdb42f0.png") repeat-x;
  position: absolute;
}

#s3a-bg-wave-1 {
  z-index: 20;
  height: 40px;
  width: 800%;
  image-rendering: pixelated;
  background-size: 1% 40px;
  animation: wave1 400s linear -5s infinite;
  bottom: 290px;
}

#s3a-bg-wave-2 {
  z-index: 22;
  height: 75px;
  width: 1000%;
  image-rendering: pixelated;
  background-size: 1.5% 80px;
  animation: wave2 150s linear infinite;
  bottom: 242px;
  left: -50vw;
}

#s3a-bg-wave-3 {
  z-index: 24;
  width: 1200%;
  image-rendering: pixelated;
  background-size: 2% 140px;
  animation: wave3 70s linear infinite;
  bottom: -305px;
  left: -100vw;
}

#s3a-bg-wave-4 {
  z-index: 26;
  width: 1500%;
  background-size: 3% 210px;
  animation: wave4 40s linear infinite;
  bottom: -345px;
  left: -150vw;
}

#s3a-bg-wave-5 {
  z-index: 28;
  width: 2000%;
  background-size: 4% 280px;
  animation: wave5 30s linear infinite;
  bottom: -383px;
  left: -150vw;
}

#s3a-bg-wave-6 {
  z-index: 30;
  width: 2500%;
  background-size: 5% 350px;
  animation: wave6 22s linear infinite;
  bottom: -445px;
  left: -150vw;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -100%;
  }
}

@keyframes wave1 {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -200%;
  }
}

@keyframes wave2 {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -160%;
  }
}

@keyframes wave3 {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -120%;
  }
}

@keyframes wave4 {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -180%;
  }
}

@keyframes wave5 {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -240%;
  }
}

@keyframes wave6 {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -125%;
  }
}

@keyframes wave7 {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -390%;
  }
}

@keyframes scale1_2 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(2);
  }
}

.sml-mountain {
  width: 140px;
  height: 51px;
  z-index: 19;
  background-repeat: no-repeat;
  position: fixed;
}

#mountains-1 {
  background-image: url("mountains-1.9174322d.svg");
}

#mountains-2 {
  background-image: url("mountains-2.1224d369.svg");
}

/*# sourceMappingURL=index.d57dbd34.css.map */
